import ABI_HistoryMarket from "./abis/HistorySeriesMinter.json"
import IERC721 from "./common/IERC721.js"
import * as wallet from "./common/wallet.js"
import * as web3util from "./common/web3util.js"

const errorPrefix = 'HistoryNewMarket | ';
class HistoryNewMarket extends IERC721 {
    async tokenSaleInfo(contractAdr,tokenId){
        const conf = await this.read('tokenSaleInfo',[contractAdr,tokenId])
        return conf;
    }
    async createSale(seriesId,maxPerUser,totalSupply,price,startTime,endTime){
        const res = await this.write(
            'createSale',
            [seriesId,maxPerUser,totalSupply,price,startTime,endTime],
            {
                gasLimit: 360000
            } 
        )
        return res
    }
    async mintSeriesToken(saleId, _count, address, _value){
        const res = await this.write(
            'mintSeriesToken',
            [saleId,_count,address],
            {
                gasLimit: 350000 * _count,
                value: _value
            }
        )
        return res
    }
    async closeMint(signature,seriesId){
        const res = await this.write(
            'closeMint',
            [signature,seriesId],
            {
                gasLimit: 360000,
            }
        )
        return res
    }
    async revealAndWithdraw(signature,seriesId,seriesBaseURI){
        const res = await this.write(
            'revealAndWithdraw',
            [signature,seriesId,seriesBaseURI],
            {
                gasLimit: 360000,
            }
        )
        return res
    }
    async saleInfo(saleId){
        const conf = await this.read('saleInfo',[saleId])
        return conf;
    }
    async numSold(saleId){
        const conf = await this.read('numSold',[saleId])
        return conf
    }
    async numCanMint(saleId,address){
        const conf = await this.read('numCanMint',[saleId,address])
        return conf
    }
}

export default HistoryNewMarket

export async function queryHistoryNewMarket() {
    const adr = await web3util.getContractAdr('SeriesMinter01');
    return new HistoryNewMarket(ABI_HistoryMarket, adr);
}
