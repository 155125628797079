<template>
    <div class="addRoundPage-container">
        <div class="addRounds-title">
            <div class="back-router" @click="backPage">
                <i class="el-icon-back"></i>
                <div>Back</div>
            </div>
            <div class="addRounds-titleName">Add</div>
        </div>
        <div class="formList-content">
            <div class="form_view locationView">
                <div class="form_view_title">Price<span>*</span></div>
                <div class="form_view_introduce">Mint Unit Price</div>
                <div class="form_view_input">
                    <input type="text" v-model="uploadtextQuery.price" placeholder="ex.2">
                    <div class="locationClick">
                        <img class="nameIcon" :src="chainImg[chainId]">
                    </div>
                </div>
            </div>
            <div class="form_view locationView">
                <div class="form_view_title">Supply<span>*</span></div>
                <div class="form_view_introduce">NFT Maximum Supply</div>
                <div class="form_view_input">
                    <input type="text" v-model="uploadtextQuery.supply" placeholder="ex.2">
                </div>
            </div>
            <div class="form_view locationView">
                <div class="form_view_title">How many total mints per address allotted<span>*</span></div>
                <div class="form_view_introduce">How many mints can each address be at most</div>
                <div class="form_view_input">
                    <input type="text" v-model="uploadtextQuery.maxmint" placeholder="ex.2">
                </div>
            </div>
            <div class="form_view locationView">
                <div class="form_view_title">Date (UTC)<span>*</span></div>
                <div class="form_view_introduce">No bidding allowed beyond the time limit listed</div>
                <div class="form_view_input-time">
                    <el-date-picker v-model="uploadtextQuery.startTime" type="datetimerange" start-placeholder="Select start time" end-placeholder="Select end time" value-format="timestamp" :picker-options="pickerOptions1">
                    </el-date-picker>
                    <i class="el-icon-date"></i>
                </div>
            </div>
        </div>
        <div class="submit-content">
            <div class="submit-cancel" @click="Cancel">Cancel</div>
            <div class="mintCreate_button">
                <button :disabled="operationLoading||uploadtextQuery.price===''||uploadtextQuery.supply===''||uploadtextQuery.maxmint===''||uploadtextQuery.startTime===''" :class="{'mintCreate_button_true': uploadtextQuery.price!==''&&uploadtextQuery.supply!==''&&uploadtextQuery.maxmint!==''&&uploadtextQuery.startTime!==''}" @click="confirm">
                    <span v-if="operationLoading" class="loading_ani">Loading<span class="dotting"></span></span>
                    <span v-else>Confirm</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
const wallet = require('../../../web3/common/wallet.js');
import moment from 'moment';
import { queryHistoryNewMarket } from "../../../web3/HistorySeriesMinter";
import { createHistoryERC72101S } from "../../../web3/HistorySeriesERC72101S";
import toLowerCaseNumber from '../../../plugins/hex2int.js'
import { ethers } from 'ethers'
import { chainName, chainImg } from "../../../web3/web3Config";
import { getChainIdNetwork } from "../../../web3/common/wallet";
const address = require("../../../web3/web3Config.js");
export default {
    data() {
        return {
            chainImg: chainImg,
            baseUrlData: '',
            operationLoading: false,
            seriesid: '',
            gettime: '',
            querytokenaddress: '',
            HistorySeriesERC721: '',
            chainName: chainName,
            pickerOptions1: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            uploadtextQuery: {
                price: '',
                supply: '',
                maxmint: '',
                startTime: '',
                admin: '',
                network: '',
            },
        }
    },
    computed: {
        mapState() {
            return this.$store.getters.walletInfo
        },
        chainId() {
            return this.$store.getters.walletInfo.chainId;
        }

    },
    watch: {
        '$store.getters.initStatusComplete': {
            immediate: true,
            handler(flag) {
                if (flag) {
                    this.init();
                }
            }
        }
    },
    mounted() {

    },
    created() {
        this.seriesid = JSON.parse(this.$route.query.id)
        this.querytokenaddress = this.$route.query.tokenAddress
        this.getCurrentTime()
    },
    methods: {
        async init() {
            this.baseUrlData = localStorage.getItem('baseUrl')
            let res = '';
            if (this.mapState.chainId) {
                res = this.mapState.chainId
            } else {
                res = await getChainIdNetwork();
            }
            this.HistorySeriesERC721 = address.address[this.chainName[res]].HistorySeriesERC721;
        },

        backPage() {
            this.$router.go(-1)
        },
        getCurrentTime() {
            let yy = new Date().getUTCFullYear();
            let mm = (new Date().getUTCMonth() + 1) < 10 ? '0' + (new Date().getUTCMonth() + 1) : new Date().getUTCMonth() + 1;
            let dd = new Date().getUTCDate() < 10 ? '0' + new Date().getUTCDate() : new Date().getUTCDate();
            let hh = new Date().getUTCHours() < 10 ? '0' + new Date().getUTCHours() : new Date().getUTCHours();
            let mf = new Date().getUTCMinutes() < 10 ? '0' + new Date().getUTCMinutes() : new Date().getUTCMinutes();
            let ss = new Date().getUTCSeconds() < 10 ? '0' + new Date().getUTCSeconds() : new Date().getUTCSeconds();
            this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
            console.log(this.gettime)
        },
        Cancel() {
            this.uploadtextQuery = {
                price: '',
                supply: '',
                maxmint: '',
                startTime: '',
            }
        },
        confirm() {
            if (moment(Number(this.uploadtextQuery.startTime[0])).format('YYYY-MM-DD HH:mm:ss') < this.gettime) {
                this.$message({
                    message: 'Time cannot be less than current time',
                    type: 'warning'
                });
                return
            }
            if (this.uploadtextQuery.price !== '' && this.uploadtextQuery.supply !== '' && this.uploadtextQuery.maxmint !== '' && this.uploadtextQuery.startTime !== '') {
                this.operationLoading = true;
                this.uploadtextRequire()
            }
        },
        uploadtextRequire() {
             if (this.mapState.chainId) {
                //obtain wallet address
                wallet.getWalletInfo().then((info) => {
                    this.uploadtextQuery.admin = info.address.toLowerCase();
                    this.uploadtextQuery.network = info.network;
                    this.mintServer();
                })
            }
        },
        mintServer() {
            let _this = this;
            let params = {
                price: this.uploadtextQuery.price,
                supply: this.uploadtextQuery.supply,
                maxmint: this.uploadtextQuery.maxmint,
                starttime: moment(Number(this.uploadtextQuery.startTime[0])).format('YYYY-MM-DD HH:mm:ss'),
                endtime: moment(Number(this.uploadtextQuery.startTime[1])).format('YYYY-MM-DD HH:mm:ss'),
                admin: this.uploadtextQuery.admin,
                network: this.uploadtextQuery.network,
                seriesid: this.seriesid,
            };
            let paramsMint = {
                seriesId: params.seriesid,
                maxPerUser: Number(params.maxmint),
                totalSupply: Number(params.supply),
                price: ethers.utils.parseUnits(String(params.price)),
                startTime: Math.floor(this.uploadtextQuery.startTime[0] / 1000),
                endTime: Math.floor(this.uploadtextQuery.startTime[1] / 1000)
            }
            if (this.querytokenaddress == this.HistorySeriesERC721) {
                queryHistoryNewMarket().then(erc721 => {
                    erc721.createSale(paramsMint.seriesId, paramsMint.maxPerUser, paramsMint.totalSupply, paramsMint.price, paramsMint.startTime, paramsMint.endTime).then((res) => {
                        let seriesidNumber = res.events[0].data.substring(2, 66)
                        let saleId = toLowerCaseNumber(seriesidNumber)
                        params.saleid = saleId
                        params.tokenaddress = this.querytokenaddress
                        if (res) {
                            _this.$axios.post(_this.baseUrlData + _this.$api.post_addround, params).then(() => {})
                            setTimeout(() => {
                                this.$router.go(-1)
                            }, 1500)
                            _this.$message({
                                message: 'Operation succeeded',
                                type: 'success'
                            });
                            _this.clearForm();
                        }
                    }).catch(() => {
                        _this.Cancel()
                        _this.operationLoading = false;
                    })
                })
            } else if (this.querytokenaddress !== this.HistorySeriesERC721) {
                let ctr = createHistoryERC72101S();
                ctr.createSale(paramsMint.seriesId, paramsMint.maxPerUser, paramsMint.totalSupply, paramsMint.price, paramsMint.startTime, paramsMint.endTime).then((res) => {
                    let seriesidNumber = res.events[0].data.substring(2, 66)
                    let saleId = toLowerCaseNumber(seriesidNumber)
                    params.saleid = saleId
                    params.tokenaddress = this.querytokenaddress
                    if (res) {
                        _this.$axios.post(_this.baseUrlData + _this.$api.post_addround, params).then(() => {})
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 1500)
                        _this.$message({
                            message: 'Operation succeeded',
                            type: 'success'
                        });
                        _this.clearForm();
                    }
                }).catch(() => {
                    _this.Cancel()
                    _this.operationLoading = false;
                })
            }
        },
        clearForm() {
            this.operationLoading = false;
            this.uploadtextQuery = {
                admin: "",
                price: '',
                supply: '',
                maxmint: '',
                startTime: '',
            };
        },
    }
}
</script>
<style lang="scss" src='../../../assets/css/addRounds.scss' scoped></style>
<style>
.form_view_input-time .el-date-editor .el-range__icon {
    display: none;
}

.form_view_input-time .el-date-editor .el-range-separator {
    padding-top: 3px;
    box-sizing: border-box;
    color: #999;
    height: 40px;
}

.form_view_input-time .el-date-editor .el-range-input {
    color: #000;
    font-size: .15rem;
    font-weight: 400;
}

.form_view_input-time .el-date-editor .el-range__close-icon {
    display: none;
}

.el-date-table td.today span {
    color: #FFE161 !important;
}

.el-date-table td.end-date span,
.el-date-table td.start-date span {
    background-color: #FFE161 !important;
    color: #fff !important;
}

.el-time-panel__btn.confirm {
    color: #FFE161 !important;
}

.el-button--mini:nth-child(2):hover {
    color: #614F00 !important;
    border-color: #FFE161 !important;
}

.el-input--small .el-input__inner:hover {
    border-color: #FFE161 !important;
}
</style>